.sms-consent-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 30px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
}

.sms-consent-container h1 {
    color: #ff7043;
    text-align: center;
}

.sms-consent-container ul {
    list-style-type: disc;
    margin-left: 20px;
}

.footer {
    text-align: center;
    margin-top: 30px;
    color: #888;
    font-size: 0.9em;
}
